/**
 * @file トップページで使用するjsファイル
 *
*/
import slick from '../../lib/_slick.min';

$('.js-mainvisual').slick({
  mobileFirst: true,
  arrows: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  autoplaySpeed: 0,
  speed: 10000,
  cssEase:'linear',
  initialSlide: 1,
  draggable: false,
  responsive: [{
      breakpoint: 767,
      settings: {
        speed: 20000
      }
  }]
});